<template>
  <b-container id="administration" :style="cssProps">
    <b-row align-h="center" align-v="center">
      <b-col md="10" sm="12" style="position:relative;">
        <router-link to="/"><b-img src="../assets/logo.png" fluid alt="Logo Actoria" id="actoria-logo"></b-img></router-link>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="2">
        <b-button size="sm" target="_blank" @click="toFront()" class="mr-1">Back to application</b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-button size="sm" target="_blank" @click="switchApp('valo')" class="mr-1 switchApp" :class="{ active : type == 'valo' }" v-if="access('valo')">{{$t("home.valorisation_360")}}</b-button>
        <b-button size="sm" target="_blank" @click="switchApp('audit')" class="mr-1 switchApp" :class="{ active : type == 'audit' }" v-if="access('audit')">{{$t("home.diagnosis_360")}}</b-button>
        <b-button size="sm" target="_blank" @click="switchApp('diag')" class="mr-1 switchApp" :class="{ active : type == 'diag' }" v-if="access('diag')">{{$t("home.strategic_diagnosis_360")}}</b-button>
      </b-col>
    </b-row>
    <b-row align-h="center" align-v="center" style="margin-top:2em;">
      <h5>{{$t("admin.welcome")}}</h5>
    </b-row>
    <b-tabs pills v-model="active_tab">
      <b-tab :title="$t('admin.administration_title')" active v-if="write(this.type)">
        <b-card :header="$t('admin.part_header')" header-tag="header" v-if="!editBlock">
          <b-table responsive striped hover
          :items="questions"
          :fields="fields_block"
          id="my-table"
          :per-page="perPage_block"
          :current-page="currentPage_block">
            <template #cell(actions)="row">
              <b-button size="sm" target="_blank" @click="copyItem(row.item,'block')" class="mr-1">{{$t("admin.duplicate_button")}}</b-button>
              <b-button size="sm" target="_blank" @click="editItem(row.item,'block')" class="mr-1">{{$t("admin.edit_button")}}</b-button>
              <b-button size="sm" target="_blank" @click="deleteItem(row.item,'block')" class="mr-1">{{$t("admin.delete_button")}}</b-button>
            </template>
          </b-table>
          <b-row align-h="end">
            <b-col cols="2">
              <b-button size="sm" target="_blank" @click="editItem(-1,'block')" class="mr-1">{{$t("admin.addpart_button")}}</b-button>
            </b-col>
          </b-row>
        </b-card>
        <b-card :header="$t('admin.question_header')" header-tag="header" v-if="editQuestion" class="question">
          <b-row>
            <b-col sm="2">
              <label for="input-small">{{$t("admin.label_label")}}:</label>
            </b-col>
            <b-col sm="8">
              <b-form-input id="input-small" size="sm" placeholder="" v-model="currentQuestion.label"></b-form-input>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="2">
              <label for="input-small">{{$t("admin.type_label")}}:</label>
            </b-col>
            <b-col sm="8">
              <b-form-select v-model="currentQuestion.type" :options="options_type" @change="updateQuestion"></b-form-select>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="2">
              <label for="input-small">{{$t("admin.information_label")}}:</label>
            </b-col>
            <b-col sm="8">
              <b-form-textarea
                v-model="currentQuestion.infos"
                rows="3"
                max-rows="6"
              ></b-form-textarea>
            </b-col>
          </b-row>
          <b-row v-if="currentQuestion.type=='select'">
            <b-col style="padding-left:2em;text-align:left">
              <b-form-checkbox id="checkbox-1" v-model="currentQuestion.multiple" value="1" unchecked-value="0">{{$t("admin.multiple_label")}}</b-form-checkbox>
            </b-col>
          </b-row>
          <b-row v-if="currentQuestion.type=='select' || currentQuestion.type=='radio'">
            <b-col>
              <b-row>
                <b-col sm="2">
                  <label for="input-small">{{$t("admin.options_label")}}:</label>
                </b-col>
              </b-row>
              <b-row v-for="(opt,indexContent) in currentQuestion.content" :key="indexContent">
                <b-col sm="2"><label :for='"content_option_"+indexContent'>{{$t("admin.label_label")}}</label></b-col>
                <b-col sm="3"><b-form-input :id='"content_option_+indexContent"' size="sm" placeholder="" v-model="currentQuestion.content[indexContent].text"></b-form-input></b-col>
                <b-col sm="2"><label :for='"content_option_value"+indexContent'>{{$t("admin.value_label")}}</label></b-col>
                <b-col sm="3"><b-form-input :id='"content_option_value"+indexContent' size="sm" placeholder="" v-model="currentQuestion.content[indexContent].value"></b-form-input></b-col>
                <b-col sm="2"></b-col>
                <b-col sm="2"><label :for='"content_option_resp"+indexContent'>{{$t("admin.answer_label")}}</label></b-col>
                <b-col sm="3"><b-form-input :id='"content_option_resp+indexContent"' size="sm" placeholder="" v-model="currentQuestion.content[indexContent].response"></b-form-input></b-col>
                <b-col sm="2">
                  <b-button size="sm" target="_blank" @click="remove_option(indexContent)" class="mr-1 squarebutton">-</b-button>
                </b-col>
              </b-row>
              <b-row>
                <b-col offset="10" cols="2">
                  <b-button size="sm" target="_blank" @click="add_option()" class="mr-1 squarebutton">+</b-button>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row v-if="currentQuestion.type=='range'">
            <b-col>
              <b-row>
                <b-col sm="2">
                  <label for="input-small">{{$t("admin.min_label")}}:</label>
                </b-col>
                <b-col sm="3"><b-form-input :id='"content_option_+indexContent_min"' size="sm" placeholder="" v-model="currentQuestion.min"></b-form-input></b-col>
                <b-col sm="2">
                  <label for="input-small">{{$t("admin.label_label")}}:</label>
                </b-col>
                <b-col sm="3"><b-form-input :id='"content_option_+indexContent_label_min"' size="sm" placeholder="" v-model="currentQuestion.label_min"></b-form-input></b-col>
              </b-row>
              <b-row>
                <b-col sm="2">
                  <label for="input-small">{{$t("admin.max_label")}}:</label>
                </b-col>
                <b-col sm="3"><b-form-input :id='"content_option_+indexContent_max"' size="sm" placeholder="" v-model="currentQuestion.max"></b-form-input></b-col>
                <b-col sm="2">
                  <label for="input-small">{{$t("admin.label_label")}}:</label>
                </b-col>
                <b-col sm="3"><b-form-input :id='"content_option_+indexContent_label_max"' size="sm" placeholder="" v-model="currentQuestion.label_max"></b-form-input></b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-button size="sm" target="_blank" @click="cancel('question')" class="mr-1">{{$t("admin.close_button")}}</b-button>
            </b-col>
          </b-row>
        </b-card>
        <b-card :header="currentBlock.libelle" header-tag="header" v-if="editBlock">
          <b-row>
            <b-col sm="2">
              <label for="input-small">{{$t("admin.label_label")}}:</label>
            </b-col>
            <b-col sm="8">
              <b-form-input id="input-small" size="sm" placeholder="" v-model="currentBlock.libelle"></b-form-input>
            </b-col>
          </b-row>
          <b-table responsive striped hover
          :items="currentBlock.question"
          ref = "table"
          :fields="fields_question"
          id="my-table"
          :per-page="perPage_question"
          :current-page="currentPage_question">
          <template #cell(move)="row">
            <b-button size="sm" target="_blank" @click="upItem(row.index,'question')" class="mr-1" v-if="row.index>0">&#8679;</b-button>
            <b-button size="sm" target="_blank" @click="downItem(row.index,'question')" class="mr-1" v-if="row.index<(currentBlock.question.length-1)">&#8681;</b-button>
          </template>
            <template #cell(actions)="row">
              <b-button size="sm" target="_blank" @click="editItem(row.item,'question')" class="mr-1">{{$t("admin.edit_button")}}</b-button>
              <b-button size="sm" target="_blank" @click="deleteItem(row.item,'question')" class="mr-1">{{$t("admin.delete_button")}}</b-button>
            </template>
          </b-table>
          <b-row align-h="end">
            <b-col cols="2">
              <b-button size="sm" target="_blank" @click="editItem(-1,'question')" class="mr-1">{{$t("admin.addquestion_button")}}</b-button>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-button size="sm" target="_blank" @click="cancel('block')" class="mr-1">{{$t("admin.cancel_button")}}</b-button>
            </b-col>
            <b-col>
              <b-button size="sm" target="_blank" @click="save('block')" class="mr-1">{{$t("admin.validate_button")}}</b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-tab>
      <b-tab :title="$t('admin.reporting_title')" v-if="report(this.type)">
        <b-pagination
          v-model="currentPage"
          :total-rows="total_rapport"
          :per-page="perPage"
          aria-controls="my-table"
          align="center"
        ></b-pagination>
          <b-table responsive striped hover
          :items="rapport"
          :fields="fields"
          id="my-table"
          :per-page="perPage"
          :current-page="currentPage">
            <template #cell(actions)="row">
              <b-button size="sm" target="_blank" :href="downloadDOC(row.item)" class="mr-1">
                DOC
              </b-button>
            </template>
            <template #row-details="row">
              <b-row class="mb-2">
                <b-col style="text-align:left;padding-left:2em;"><b>Ref: {{ row.item.ref_chantier }}</b></b-col>
              </b-row>
          </template>
          </b-table>
      </b-tab>
      <b-tab :title="$t('admin.users_title')" v-if="user_role(this.type)">
        <b-card header="User list" header-tag="header" v-if="!editUser">
          <b-pagination
            v-model="currentUserPage"
            :total-rows="total_user"
            :per-page="perPage"
            aria-controls="my-table"
            align="center"
          ></b-pagination>
            <b-table responsive striped hover
            :items="users"
            :fields="fields_user"
            id="my-table"
            :per-page="perPage"
            :current-page="currentUserPage">
              <template #cell(actions)="row">
                <b-button size="sm" target="_blank" @click="activateUser(row.item)" class="mr-1" :disabled="row.item.activate==1">
                  {{$t("admin.activate_button")}}
                </b-button>
                <b-button size="sm" target="_blank" @click="editItem(row.item,'user')" class="mr-1">
                  {{$t("admin.handle_button")}}
                </b-button>
                <b-button size="sm" target="_blank" @click="deleteItem(row.item,'user')" class="mr-1">
                  {{$t("admin.delete_button")}}
                </b-button>
              </template>
            </b-table>
            <b-row align-h="end">
              <b-col cols="2">
                <b-button size="sm" target="_blank" @click="editItem(-1,'user')" class="mr-1">Créer Utilisateur</b-button>
              </b-col>
            </b-row>
        </b-card>
        <b-card header="Utilisateur" header-tag="header" v-if="editUser">
          <b-row>
            <b-col sm="2">
              <label for="input-small">Email:</label>
            </b-col>
            <b-col sm="8">
              <b-form-input id="input-small" size="sm" placeholder="" v-model="currentUser.email"></b-form-input>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="2">
              <label for="input-small">Nom:</label>
            </b-col>
            <b-col sm="8">
              <b-form-input id="input-small" size="sm" placeholder="" v-model="currentUser.nom"></b-form-input>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="2">
              <label for="input-small">Prénom:</label>
            </b-col>
            <b-col sm="8">
              <b-form-input id="input-small" size="sm" placeholder="" v-model="currentUser.prenom"></b-form-input>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="2">
              <label for="checkbox-admin">Admin:</label>
            </b-col>
            <b-col sm="2">
              <b-form-checkbox id="checkbox-admin" v-model="currentUser.is_admin" value="1" unchecked-value="0"></b-form-checkbox>
            </b-col>
          </b-row>
          <hr>
          <b-row>
            <b-col sm="2">
              <label for="checkbox-admin">Valorisation 360 :</label>
            </b-col>
            <b-col sm="5">
              <b-form-checkbox id="checkbox-valo360-admin" @change="checkAdmin('valo','r',$event)" :checked="isAdmin('valo','r')">Accès</b-form-checkbox>
            </b-col>
            <b-col sm="5">
              <b-form-checkbox id="checkbox-valo360-admin" @change="checkAdmin('valo','w',$event)" :checked="isAdmin('valo','w')">Administration</b-form-checkbox>
            </b-col>
            <b-col sm="2"> </b-col>
            <b-col sm="5">
              <b-form-checkbox id="checkbox-valo360-report" @change="checkAdmin('valo','a',$event)" :checked="isAdmin('valo','a')">Reporting</b-form-checkbox>
            </b-col>
            <b-col sm="5">
              <b-form-checkbox id="checkbox-valo360-user" @change="checkAdmin('valo','u',$event)" :checked="isAdmin('valo','u')">Utilisateur</b-form-checkbox>
            </b-col>
          </b-row>
          <hr>
          <b-row>
            <b-col sm="2">
              <label for="checkbox-admin">Diagnostic Immatériel :</label>
            </b-col>
            <b-col sm="5">
              <b-form-checkbox id="checkbox-rolediag360-admin" @change="checkAdmin('audit','r',$event)" :checked="isAdmin('audit','r')">Accès</b-form-checkbox>
            </b-col>
            <b-col sm="5">
              <b-form-checkbox id="checkbox-rolediag360-admin" @change="checkAdmin('audit','w',$event)" :checked="isAdmin('audit','w')">Administration</b-form-checkbox>
            </b-col>
            <b-col sm="2"> </b-col>
            <b-col sm="5">
              <b-form-checkbox id="checkbox-rolediag360-report" @change="checkAdmin('audit','a',$event)" :checked="isAdmin('audit','a')">Reporting</b-form-checkbox>
            </b-col>
            <b-col sm="5">
              <b-form-checkbox id="checkbox-rolediag360-user" @change="checkAdmin('audit','u',$event)" :checked="isAdmin('audit','u')">Utilisateur</b-form-checkbox>
            </b-col>
          </b-row>
          <hr>
          <b-row>
            <b-col sm="2">
              <label for="checkbox-admin">Diagnostic Strat. :</label>
            </b-col>
            <b-col sm="5">
              <b-form-checkbox id="checkbox-diag-admin" @change="checkAdmin('diag','r',$event)" :checked="isAdmin('diag','r')">Accès</b-form-checkbox>
            </b-col>
            <b-col sm="5">
              <b-form-checkbox id="checkbox-diag-admin" @change="checkAdmin('diag','w',$event)" :checked="isAdmin('diag','w')">Administration</b-form-checkbox>
            </b-col>
            <b-col sm="2"> </b-col>
            <b-col sm="5">
              <b-form-checkbox id="checkbox-diag-report" @change="checkAdmin('diag','a',$event)" :checked="isAdmin('diag','a')">Reporting</b-form-checkbox>
            </b-col>
            <b-col sm="5">
              <b-form-checkbox id="checkbox-diag-user" @change="checkAdmin('diag','u',$event)" :checked="isAdmin('diag','u')">Utilisateur</b-form-checkbox>
            </b-col>
          </b-row>
          <hr>
          <b-row>
            <b-col sm="2">
              <label for="input-small">Définir Mot de passe:</label>
            </b-col>
            <b-col sm="8">
              <b-form-input id="input-small" size="sm" placeholder="" v-model="currentUser.password"></b-form-input>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-button size="sm" target="_blank" @click="cancel('user')" class="mr-1">Annuler</b-button>
            </b-col>
            <b-col>
              <b-button size="sm" target="_blank" @click="save('user')" class="mr-1">Valider</b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-tab>
    </b-tabs>
  </b-container>
</template>

<script>

export default {
  name: 'Admin',
  data() {
      return {
        maincolorFrom: "#0086cb",
        maincolorTo: "#1d1b59",
        subtitle: this.$t("admin.welcome"),
        user:{},
        role:{},
        server: "https://extractoria.com",
        type: 'valo',
        active_tab:0,
        //server: "http://localhost",
        editBlock:false,
        editUser:false,
        currentBlock:{
          question:[],
          libelle:this.$t("admin.new_part"),
          id:-1
        },
        editQuestion:false,
        currentQuestion:{
          label:this.$t("admin.new_question"),
          type:null,
          infos:"",
          id: -1
        },
        currentUser:{
          role:{audit:"r",diag:"r",valo:"r"},
          nom:"",
          password:"",
          prenom:"",
          id:-1,
          email:"",
          is_admin:0,
          activate:1,
        },
        rapport: [],
        users: [],
        questions: [],
        perPage_question: 50,
        currentPage_question: 1,
        currentUserPage: 1,
        perPage_block: 15,
        currentPage_block: 1,
        start:null,
        end:null,
        perPage: 15,
        currentPage: 1,
        file_label:{
          'audit':'audit',
          'valo':'diagnostic',
          'diag':'diagnostic'
        },
      }
    },
    methods: {
      checkAdmin(role,right,$event){
        if($event){
          this.currentUser.role[role] += right;
        }else{
          this.currentUser.role[role] = this.currentUser.role[role].replace(right, '');
        }
      },
      isAdmin(role,right){
        console.log(this.currentUser.role);
        return this.currentUser.role[role].includes(right);
      },
      toFront(){
        this.$router.push('/');
      },
      upItem(index,type){
        console.log(index);
        console.log(this.currentBlock.question);
        switch(type){
          case 'question':
            [this.currentBlock.question[index], this.currentBlock.question[index-1]] = [this.currentBlock.question[index-1], this.currentBlock.question[index]];
            this.$refs.table.refresh();
          break;
        }

        console.log(this.currentBlock.question);
      },
      downItem(index,type){
        switch(type){
          case 'question':
            [this.currentBlock.question[index], this.currentBlock.question[index+1]] = [this.currentBlock.question[index+1], this.currentBlock.question[index]];
            this.$refs.table.refresh();
          break;
        }

      },
      deleteItem(item,type){
        switch(type){
          case 'block':
            var index = this.questions.findIndex((el) => el.id==item.id);
            if(index>-1) {
              this.questions.splice(index,1);
              const baseURI = this.server+':3000/'+this.type+'/questions/'+item.id+'?lang=' + this.$i18n.locale;
              this.$http.delete(baseURI)
              .then(() => {
                console.log("ok");
              });
            }
          break;
          case 'question':
            var index_question = this.currentBlock.question.findIndex((el) => el.id==item.id);
            if(index_question>-1) this.currentBlock.question.splice(index_question,1);
          break;
          case 'user':
            var index_user = this.users.findIndex((el) => el.id==item.id);
            if(index_user>-1) {
              this.users.splice(index_user,1);
              const baseURI = this.server+':3000/users/'+item.id;
              this.$http.delete(baseURI)
              .then(() => {
                console.log("ok");
              });
            }
          break;
        }
      },
      copyItem(item,type){
        switch(type){
          case 'block':
            this.currentBlock = item;
            this.currentBlock.id = this.questions.length+1;
            this.questions.push(this.currentBlock);
            var baseURI = this.server+':3000/'+this.type+'/questions';
            this.$http.post(baseURI,{question:this.currentBlock, lang:this.$i18n.locale})
            .then(() => {
              console.log("ok");
            });
            this.currentBlock = {
              question:[],
              libelle:this.$t("admin.new_part"),
              id:-1
            }
          break;
        }
      },
      editItem(item,type){
        switch(type){
          case 'block':
            this.editBlock = true;
            if(item!=-1){
              this.currentBlock = item;
            }
            else {
              this.currentBlock.libelle = this.$t("admin.new_part");
            }
          break;
          case 'question':
            this.editQuestion = true;
            if(item!=-1){
              this.currentQuestion = item;
            }
            else {
              this.currentQuestion.label = this.$t("admin.new_question");
            }
          break;
          case 'user':
            this.editUser = true;
            if(item!=-1){
              this.currentUser = item;
              if(this.currentUser.role!=""){
                this.currentUser.role = JSON.parse(this.currentUser.role);
              }else{
                this.currentUser.role = {audit:"r",diag:"r",valo:"r"};
              }
            }
          break;
        }

      },
      cancel(type){
        switch(type){
          case 'block':
            this.currentBlock = {
              question:[],
              libelle:this.$t("admin.new_part"),
              id:-1
            }
            this.editBlock = false;
          break;
          case 'user':
            this.currentUser= {
              role:{audit:"r",diag:"r",valo:"r"},
              nom:"",
              password:"",
              prenom:"",
              id:-1,
              email:"",
              is_admin:0,
              activate:1,
            },
            this.editUser = false;
          break;
          case 'question':
            this.editQuestion = false;
            if(this.currentQuestion.id==-1){
              this.currentQuestion.id = this.currentBlock.question.length + 1;
              this.currentBlock.question.push(this.currentQuestion);
            }

            this.currentQuestion = {
              label:this.$t("admin.new_question"),
              type:null,
              id: -1,
            }
          break;
        }
      },
      save(type){
        switch(type){
          case 'block':
            this.editBlock = false;
            var index = this.questions.findIndex((el) => el.id==this.currentBlock.id);
            if(index>-1) {
              this.questions[index] = this.currentBlock;
              const baseURI = this.server+':3000/'+this.type+'/questions';
              this.$http.put(baseURI,{question:this.questions[index], lang:this.$i18n.locale})
              .then(() => {
                console.log("ok");
              });
            }else{
              this.currentBlock.id = this.questions.length+1;
              this.questions.push(this.currentBlock);
              const baseURI = this.server+':3000/'+this.type+'/questions';
              this.$http.post(baseURI,{question:this.currentBlock, lang:this.$i18n.locale})
              .then(() => {
                console.log("ok");
              });
            }
            this.currentBlock = {
              question:[],
              libelle:this.$t("admin.new_part"),
              id:-1
            }
          break;
          case 'user':
            this.editUser = false;
            console.log(this.currentUser);
            var baseURI = this.server+':3000/'+this.type+'/create';
            this.$http.post(baseURI,{user:this.currentUser}).then((res) => {
              console.log("ok");
              console.log(res.data);
                if(this.currentUser.id==-1) {
                  this.users.push(res.data.user);
                }else{
                  var index = this.users.findIndex((el) => el.id==this.currentUser.id);
                  this.users[index] = res.data.user;
                }
                this.currentUser = {
                  role:{audit:"r",diag:"r",valo:"r"},
                  nom:"",
                  prenom:"",
                  password:"",
                  id:-1,
                  email:"",
                  is_admin:0,
                  activate:1,
                };
            });


          break;
        }
      },
      downloadDOC(item){
        console.log(item);
        return this.server+":3000/rapport_"+this.file_label[this.type]+"_"+item.id_user+"_"+item.id+".docx";
      },
      remove_option(index){
        this.currentQuestion.content.splice(index,1);
      },
      add_option(){
        this.currentQuestion.content.push({text:'',value:''});
      },
      updateQuestion(){
        if(this.currentQuestion.type=="select" && !this.currentQuestion.content){
          this.$set(this.currentQuestion,'content',[]);
          this.$set(this.currentQuestion,'multiple',0);
        }else if(this.currentQuestion.type=="range"){
          this.$set(this.currentQuestion,'min',0);
          this.$set(this.currentQuestion,'max',100);
          this.$set(this.currentQuestion,'label_min','');
          this.$set(this.currentQuestion,'label_max','');
        }
        console.log(this.currentQuestion);
      },
      switchApp(type){
        this.editQuestion=false;
        this.editBlock=false;
        this.active_tab = 0;
        this.type = type;
        if(this.write(this.type)){
        var baseURI = this.server+':3000/'+this.type+'/questions?lang=' + this.$i18n.locale;
        this.$http.get(baseURI)
        .then((result) => {
          this.questions = result.data;
          if(this.report(this.type)){
            baseURI = this.server+':3000/'+this.type+'/rapport';
            this.$http.get(baseURI)
            .then((result) => {
              this.rapport = result.data;
            });
          }
        });
        }else{
          if(this.report(this.type)){
            baseURI = this.server+':3000/'+this.type+'/rapport';
            this.$http.get(baseURI)
            .then((result) => {
              this.rapport = result.data;
            });
          }
        }

        switch(this.type){
          case 'audit':
            this.maincolorFrom = "#cb000a";
            this.maincolorTo = "#591b1b";
          break;
          case 'diag':
            this.maincolorFrom = "#00cb43";
            this.maincolorTo = "#1b592f";
          break;
          case 'valo':
            this.maincolorFrom = "#0086cb";
            this.maincolorTo = "#1d1b59";
          break;
        }
        this.$store.dispatch('user/setApp',{type:this.type,maincolorFrom:this.maincolorFrom,maincolorTo:this.maincolorTo,subtitle:this.subtitle});
      },
      activateUser(user){
        var index = this.users.findIndex((el) => el.id==user.id);
        if(index>-1) {
          var baseURI = this.server+':3000/activeUser';
          this.$http.post(baseURI,{user_id:this.users[index].id})
          .then(() => {
            console.log("ok");
            this.users[index].activate = 1;
          });
        }
      },
      write(type){
        var res = false;
        switch(type){
          case 'audit':
            res = (("audit" in this.role) && this.role.audit.includes('w'));
          break;
          case 'diag':
            res = (("diag" in this.role) && this.role.diag.includes('w'));
          break;
          case 'valo':
            res = (("valo" in this.role) && this.role.valo.includes('w'));
          break;
        }
        return res;
      },
      report(type){
        var res = false;
        switch(type){
          case 'audit':
            res = (("audit" in this.role) && this.role.audit.includes('a'));
          break;
          case 'diag':
            res = (("diag" in this.role) && this.role.diag.includes('a'));
          break;
          case 'valo':
            res = (("valo" in this.role) && this.role.valo.includes('a'));
          break;
        }
        return res;
      },
      user_role(type){
        var res = false;
        switch(type){
          case 'audit':
            res = (("audit" in this.role) && this.role.audit.includes('u'));
          break;
          case 'diag':
            res = (("diag" in this.role) && this.role.diag.includes('u'));
          break;
          case 'valo':
            res = (("valo" in this.role) && this.role.valo.includes('u'));
          break;
        }
        return res;
      },
      access(type){
        var res = false;
        switch(type){
          case 'audit':
            res = (("audit" in this.role) && (this.role.audit.includes('a') || this.role.audit.includes('u') ||this.role.audit.includes('w')));
          break;
          case 'diag':
            res = (("diag" in this.role) && (this.role.diag.includes('a') || this.role.diag.includes('u') ||this.role.diag.includes('w')));
          break;
          case 'valo':
            res = (("valo" in this.role) && (this.role.valo.includes('a') || this.role.valo.includes('u') ||this.role.valo.includes('w')));
          break;
        }
        return res;
      },
    },
    computed: {
      cssProps() {
        return {
          '--maincolorGradientFrom': this.maincolorFrom,
          '--maincolorGradientTo': this.maincolorTo
        }
      },
      total_rapport(){
        return this.rapport.length;
      },
      total_user(){
        return this.users.length;
      },
      options_type() { return [
          { value: null, text: this.$t("admin.select_option_label") },
          { value: 'input', text: this.$t("admin.short_text_label") },
          { value: 'radio', text: this.$t("admin.unique_label") },
          { value: 'select', text: this.$t("admin.select_label") },
          { value: 'date', text: this.$t("admin.date_label") },
          { value: 'number', text: this.$t("admin.number_label") },
          { value: 'tel', text: this.$t("admin.tel_label") },
          { value: 'textarea', text: this.$t("admin.long_text_label") },
          { value :'range', text:this.$t("admin.range_label") },
        ];
      },
      fields_block() { return [
          {
            key: 'libelle',
            label: this.$t("admin.label_label"),
            tdClass: 'col_label'
          },
          {
            key: 'question',
            label: this.$t("admin.nb_question_label"),
            formatter: value => {
              return value.length
            }
          },
          { key: 'actions', label: this.$t("admin.actions_label") }
        ];
      },
      fields_question() { return [
          { key: 'id', label: this.$t("admin.id_label") },
          { key: 'move', label: this.$t("admin.move_label") },
          {
            key: 'label',
            label: this.$t("admin.label_label"),
            tdClass: 'col_label'
          },
          {
            key: 'type',
            label: this.$t("admin.type_question_label"),
          },
          { key: 'actions', label: 'Actions' }
        ];
      },
      fields_user() { return [
          {
            key: 'nom',
            label: this.$t("login.name_label"),
            sortable: true,
          },
          {
            key: 'prenom',
            label: this.$t("login.firstname_label"),
            sortable: true,
          },
          {
            key: 'email',
            label: this.$t("login.email_label"),
            sortable: true,
          },
          { key: 'actions', label: this.$t("admin.actions_label") }
        ];
      },
      fields() { return [
          {
            key: 'created_at',
            label: this.$t("admin.date_label"),
            sortable: true,
            formatter: value => {
              return value.split("T")[0]
            }
          },
          {
            key: 'name',
            label: this.$t("admin.report_name"),
            sortable: true,
          },
          {
            key: 'nom',
            label: this.$t("admin.user_lastname"),
            sortable: true,
          },
          {
            key: 'prenom',
            label: this.$t("admin.user_firstname"),
            sortable: true,
          },
          {
            key: 'email',
            label: this.$t("admin.user_email"),
            sortable: true,
          },
          { key: 'actions', label: this.$t("admin.actions_label") }
        ];
      }
    },mounted(){
      localStorage.setItem("current-view", "Admin");
      window.dispatchEvent(new CustomEvent('current-view-localstorage-changed', {
        detail: {
          storage: localStorage.getItem('current-view')
        }
      }));
      this.user = this.$store.state.user.user;
      if(this.user.is_admin == 0) {
        this.$router.push('/');
      }

      this.role = JSON.parse(this.user.role)
      if(this.write(this.type)){
        var baseURI = this.server+':3000/'+this.type+'/questions?lang=' + this.$i18n.locale;
      this.$http.get(baseURI)
        .then((result) => {
          this.questions = result.data;
        });
      }

      if(this.report(this.type)){
        baseURI = this.server+':3000/'+this.type+'/rapport';
        this.$http.get(baseURI)
        .then((result) => {
          this.rapport = result.data;
        });
      }

      if(this.user_role(this.type)){
        baseURI = this.server+':3000/users';
        this.$http.get(baseURI)
        .then((result) => {
          this.users = result.data;
        });
      }

      if(!this.access(this.type)){
        console.log(this.role);
        console.log("change type");
        this.type = "audit";
        if(!this.access(this.type)){
          this.type = "diag";
          if(!this.access(this.type)){
            this.type = "";
          }
        }
      }
      console.log("debug type");
      console.log(this.user);
      console.log(this.type);

      switch(this.type){
        case 'audit':
          this.maincolorFrom = "#cb000a";
          this.maincolorTo = "#591b1b";
        break;
        case 'diag':
          this.maincolorFrom = "#00cb43";
          this.maincolorTo = "#1b592f";
        break;
        case 'valo':
          this.maincolorFrom = "#0086cb";
          this.maincolorTo = "#1d1b59";
        break;
      }
    },
    watch: {
      '$i18n.locale': function(newVal) {
        var baseURI = this.server+':3000/'+this.type+'/questions?lang=' + newVal;
        this.$http.get(baseURI)
        .then((result) => {
          this.questions = result.data;
        });
        this.editBlock = false;
        this.currentBlock = {
          question:[],
          libelle:this.$t("admin.new_part"),
          id:-1
        };
        this.editQuestion = false;
        this.currentQuestion = {
          label:this.$t("admin.new_question"),
          type:null,
          infos:"",
          id: -1
        };
      }
    }
}
</script>

<style lang="scss">
#administration{
  &.switchApp{
    box-shadow: 5px 5px 5px 0px rgb(0 0 0 / 30%), 4px 4px 15px 0px #fff;
    padding: 1em 2em;
    border-radius: 2em;
    color: #1d1b59;
    background: linear-gradient(122deg, #f8f8fc 0%, #e1e2e8 100%, #00d4ff 100%) !important;
    border: 0px;
    font-size:1.25em;

    &.active{
      background: linear-gradient(122deg, rgba(0,134,203,1) 0%, rgba(29,27,89,1) 100%) !important;
      box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.3), 4px 4px 15px 0px #fff;
      color:white;
    }
  }

  .col_label{
    max-width: 500px;
  }
  .card {
    margin-top:2em;
    margin-bottom:2em;
    &.question{
      .row{
        margin-bottom:1em;
      }
    }
    .card-body{
      padding:1.25rem 0;
    }
  }
  table.table {
    tr.b-table-details td{
      padding-top:0;
    }
    th, td{
      font-size: 9pt;
    }
  }

  #actoria-logo{
    max-width: 40%;
    margin-bottom: 3em;
  }
}
</style>
