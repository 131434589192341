<template>
    <b-container class="report" fluid="xl" :style="cssProps">
      <b-row align-h="center" align-v="center">
        <b-col md="10" sm="12" style="position:relative;">
          <router-link to="/"><b-img src="../assets/logo.png" fluid alt="Logo Actoria" id="actoria-logo"></b-img></router-link>
        </b-col>
      </b-row>
      <b-row align-h="center" align-v="center" style="margin-top:2em;">
        <h5>{{$t(subtitle)}}</h5>
      </b-row>
      <b-tabs pills v-model="step">
        <b-tab :title="block.libelle" v-for="(block,index) in questions" :key="index" @click="resetSubStep()">
          <div v-for="(question,index2) in block.question" :key="index2">
              <b-row v-if="sub_step==index2">
                <b-col sm="6">
                  <label :for="block.id+'_'+question.id">{{question.label}}</label>
                </b-col>
                <b-col sm="6">
                  <b-form-input :id="block.id+'_'+question.id" size="sm" placeholder="" v-model="data[block.id][question.id]" v-if="question.type=='input'"></b-form-input>
                  <b-form-input :id="block.id+'_'+question.id" size="sm" placeholder="" type="tel" v-model="data[block.id][question.id]" v-if="question.type=='tel'"></b-form-input>
                  <b-form-input :id="block.id+'_'+question.id" size="sm" placeholder="" @input="thousandSeparator(block.id,question.id)" v-model="temp[block.id][question.id]" v-if="question.type=='number' && forceUpdateInput"></b-form-input>
                  <b-row v-if="question.type=='range'">
                    <b-col sm="2">
                      <label>{{question.min}} <span v-if="question.max=='100'">%</span></label>
                      <span v-if="question.label_min!=''"><br>{{question.label_min}}</span>
                    </b-col>
                    <b-col sm="8" class="range_bubble">
                      <output class="bubble" :class="'range_'+block.id+'_'+question.id">{{data[block.id][question.id]}}</output>
                      <b-form-input :id="block.id+'_'+question.id" size="sm" placeholder="" type="range" v-model="data[block.id][question.id]" :min="question.min" :max="question.max" @input="setBubble(block.id,question.id,question.min,question.max)"></b-form-input>
                    </b-col>
                    <b-col sm="2">
                      <label>{{question.max}} <span v-if="question.max=='100'">%</span></label>
                      <span v-if="question.label_max!=''"><br>{{question.label_max}}</span>
                    </b-col>
                  </b-row>
                  <b-form-input :id="block.id+'_'+question.id" size="sm" placeholder="" type="date" :max="max_date" v-model="data[block.id][question.id]" v-if="question.type=='date'"></b-form-input>
                  <b-form-textarea
                    :id="block.id+'_'+question.id"
                    v-model="data[block.id][question.id]"
                    :placeholder="$t('report.textarea_placeholder')"
                    rows="3"
                    max-rows="6"
                    v-if="question.type=='textarea'"
                  ></b-form-textarea>
                  <b-row v-if="question.type=='radio'">
                    <b-form-group label="Individual radios">
                      <b-form-radio v-model="data[block.id][question.id]" name="some-radios" :value="question.content[indexContent].value" v-for="(opt,indexContent) in question.content" :key="indexContent">{{question.content[indexContent].text}}</b-form-radio>
                    </b-form-group>
                  </b-row>
                  <b-form-select :id="block.id+'_'+question.id" v-model="data[block.id][question.id]" :options="question.content" :multiple="question.multiple" size="sm" class="mt-3" v-if="question.type=='select'"></b-form-select>
                  <b-row v-if="tooltip(question)">
                    <b-col style="text-align:left;font-style: italic;">
                    {{question.infos}}
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
          </div>
          <b-row>
            <b-col cols="2">
              <b-button size="sm" target="_blank" @click="prev()" class="mr-1">{{$t("report.before_button")}}</b-button>
            </b-col>
            <b-col cols="8">
            </b-col>
            <b-col cols="2">
              <b-button size="sm" target="_blank" @click="next()" class="mr-1">{{$t("report.next_button")}}</b-button>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="2">
              <b-button size="sm" target="_blank" class="mr-1" @click="goHome()">{{$t("report.home_button")}}</b-button>
            </b-col>
            <b-col cols="8">
            </b-col>
            <b-col cols="2">
              <b-button size="sm" target="_blank" class="mr-1" @click="saveReport()">{{$t("report.save_report_button")}}</b-button>
            </b-col>
          </b-row>
        </b-tab>
        <b-tab :title="$t('report.report_title')" title-item-class="disabledTab" :disabled="last_tab_disabled">
          <b-row>
            <b-col>
              <b-row v-if="!generating">
                <b-col>
                  <h3>{{$t("report.thanks_1")}}</h3>
                  <p>{{$t("report.generation_1")}}</p>
                </b-col>
              </b-row>
              <b-row v-if="generating">
                <b-col>
                  <h3>{{$t("report.thanks_2")}}</h3>
                  <p>{{$t("report.generation_2")}}</p>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-button size="sm" target="_blank" @click="generate()" :href="fileurl" ref="fileURL" class="mr-1" :disabled="generating">{{$t("report.download_button")}}</b-button>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-tab>
      </b-tabs>

    </b-container>
</template>

<script>

export default {
  name: 'Report',
  data() {
    const now = new Date()
    const maxDate = now.toISOString().split('T')[0]
    return {
      generated: false,
      generating: false,
      fileurl:"",filename:"",fileurlpdf:"",filenamepdf:"",
      forceUpdateInput:true,
      user: {},
      step: 0,
      sub_step: 0,
      questions: [],
      data: { infos: {} },
      temp: {},
      max_date: maxDate,
      // server: "http://localhost",
      last_tab_disabled:true,
      server: 'https://extractoria.com',
      type: '',
      subtitle:'',
      maincolorFrom:'',
      maincolorTo:'',
      file_label:{
        'audit':'audit',
        'valo':'valorisation_immaterielle',
        'diag':'diagnostic'
      },
    }
  },
  methods:{
    thousandSeparator(b_id,q_id) {
         if (this.temp[b_id][q_id] !== '' || this.temp[b_id][q_id] !== undefined || this.temp[b_id][q_id] !== 0  || this.temp[b_id][q_id] !== '0' || this.temp[b_id][q_id] !== null) {
          this.temp[b_id][q_id] = this.temp[b_id][q_id].toString().replace(/\s/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, " ");
          this.data[b_id][q_id] = parseInt(this.temp[b_id][q_id].toString().replace(/\s/g, ""));
          this.forceUpdateInput=false;
          this.forceUpdateInput=true;
         }
     },
    saveReport(){
      console.log("saveReport");
      const baseURI = this.server+':3000/'+this.type+'/generateRapport';
      this.data.infos['id_user'] = this.user.id
      if(this.currentRapport==-1){
        console.log(this.rapports);
        this.$http.post(baseURI,{data:this.data,id:-1,lang:this.$i18n.locale})
        .then((result) => {
          if(result.data.success){
            if(this.rapports.length>0){
              this.rapports[this.type].unshift(result.data.rapport);
            }else{
              this.rapports[this.type][0] = result.data.rapport;
            }
            this.$store.dispatch('user/setRapport',this.rapports);
            this.$router.push('/home');

          }
        });
      }else{
        console.log(this.rapports);
        let indexRapport = this.rapports[this.type].findIndex((item) => { return item.id==this.currentRapport.id });

        this.$http.post(baseURI,{data:this.data,id:this.currentRapport.id,created_at:this.rapports[this.type][indexRapport].created_at,lang:this.$i18n.locale})
        .then((result) => {
          if(result.data.success){
            this.rapports[this.type][indexRapport].content = this.data;
            this.rapports[this.type][indexRapport].name = this.data["1"]["1"];
            this.rapports[this.type][indexRapport].generated = true;
            this.$store.dispatch('user/setRapport',this.rapports);
            this.$router.push('/home');
          }
        });
      }
    },
    generate(){
      if(!this.generated){
        this.generating = true;
        console.log("generate");
        const baseURI = this.server+':3000/'+this.type+'/generateRapport';
        this.data.infos['id_user'] = this.user.id
        if(this.currentRapport==-1){
          console.log(this.rapports);
          this.$http.post(baseURI,{data:this.data,id:-1,lang:this.$i18n.locale})
          .then((result) => {
            if(result.data.success){
              if(this.rapports[this.type].length>0){
                console.log(this.rapports[this.type]);
                console.log(this.type);
                this.rapports[this.type].unshift(result.data.rapport);
              }else{
                this.rapports[this.type][0] = result.data.rapport;
              }

              this.generating = false;
              this.fileurl = this.server+":3000/rapport_"+this.file_label[this.type]+"_"+this.user.id+"_"+result.data.rapport.id+".docx";
              this.filename = this.file_label[this.type]+"_"+this.user.id+"_"+this.currentRapport.id+".docx";
              this.downloadFile();
              this.$store.dispatch('user/setRapport',this.rapports);
              this.$router.push('/home');

            }
          });
        }else{
          console.log(this.rapports);
          let indexRapport = this.rapports[this.type].findIndex((item) => { return item.id==this.currentRapport.id });

          this.$http.post(baseURI,{data:this.data,id:this.currentRapport.id,created_at:this.rapports[this.type][indexRapport].created_at,lang:this.$i18n.locale})
          .then((result) => {
            if(result.data.success){
              this.rapports[this.type][indexRapport].content = this.data;
              this.rapports[this.type][indexRapport].name = this.data["1"]["1"];
              this.rapports[this.type][indexRapport].generated = true;

              this.generating = false;
              this.fileurl = this.server+":3000/rapport_"+this.file_label[this.type]+"_"+this.user.id+"_"+this.currentRapport.id+".docx";
              this.filename = this.file_label[this.type]+"_"+this.user.id+"_"+this.currentRapport.id+".docx";
              this.downloadFile();
              this.$store.dispatch('user/setRapport',this.rapports);
              this.$router.push('/home');
            }
          });
        }
      }
    },
    downloadFile() {
        // Create a new anchor element
        const a = document.createElement('a');

        // Set the href and download attributes for the anchor element
        // You can optionally set other attributes like `title`, etc
        // Especially, if the anchor element will be attached to the DOM
        a.href = this.fileurl;
        a.download = this.filename || 'download';

        // Programmatically trigger a click on the anchor element
        // Useful if you want the download to happen automatically
        // Without attaching the anchor element to the DOM
        // Comment out this line if you don't want an automatic download of the blob content
        a.click();
        console.log(this.fileurl);
    },
    resetSubStep(){
      this.sub_step = 0;
      console.log(this.data);
    },
    next(){
      /*console.log('called');
      console.log(this.questions);
      console.log(this.questions[this.step]['question'][this.sub_step]);
      console.log(this.data[this.questions[this.step]['id']][this.questions[this.step]['question'][this.sub_step]['id']]);
      console.log("!ok");*/
      this.last_tab_disabled=false;
      //console.log("ok");
      if(this.data[this.questions[this.step]['id']][this.questions[this.step]['question'][this.sub_step]['id']]==null){
        this.data[this.questions[this.step]['id']][this.questions[this.step]['question'][this.sub_step]['id']]="";
      }
        this.sub_step++;
        //console.log(this.questions[this.step]['question'][this.sub_step]);
        //console.log(this.data[this.questions[this.step]['id']][this.questions[this.step]['question'][this.sub_step]['id']]);
        if(this.sub_step==this.questions[this.step]['question'].length){
          this.sub_step=0;
          this.step++;
          /*console.log(this.questions.length);
          console.log(this.step);*/
          if(this.step==(this.questions.length)){
            /*console.log("ok");
            const baseURI = this.server+':3000/rapport';
            this.data.infos['id_user'] = this.user.id
            if(this.currentRapport==-1){
              this.$http.post(baseURI,{data:this.data,id:-1})
              .then((result) => {
                if(result.data.success){
                  this.rapports.unshift(result.data.rapport);
                  this.$store.dispatch('user/setRapport',this.rapports);
                }
              });
            }else{
              let indexRapport = this.rapports.findIndex((item) => { return item.id==this.currentRapport.id });

              this.$http.post(baseURI,{data:this.data,id:this.currentRapport.id,created_at:this.rapports[indexRapport].created_at})
              .then((result) => {
                if(result.data.success){
                  this.rapports[indexRapport].content = this.data;
                  this.rapports[indexRapport].name = this.data["1"]["1"];
                  this.$store.dispatch('user/setRapport',this.rapports);
                }
              });
            }*/
          }
        }
    },
    tooltip(question){
      return Object.prototype.hasOwnProperty.call(question,'infos') && question.infos!="";
    },
    prev(){
      console.log('called');
      if(this.sub_step==0){
        if(this.step>0){
          this.step--;
        }
      }else{
        this.sub_step--;
      }
    },
    reset(){
      console.log("reset");
      console.log(this.data);
      this.step=0;
      this.data ={ infos: {} };
      this.questions.forEach((item)=>{
        this.data[item.id] = {};
        item.question.forEach((item2) => {
          this.data[item.id][item2.id] = null;
        });
      });
    },
    goHome(){
      this.$router.push('home');
    },
    setBubble(b_id,q_id,min,max) {
      const val = this.data[b_id][q_id];
      const newVal = Number(((val - min) * 100) / (max - min));
      document.querySelector('.range_'+b_id+'_'+q_id).innerHTML = val;
      document.querySelector('.range_'+b_id+'_'+q_id).style.left = `calc(${newVal}%)`;
    },
    onLngLoad() {
      this.type = this.$store.state.user.current_app.type;
      this.maincolorFrom = this.$store.state.user.current_app.maincolorFrom;
      this.maincolorTo = this.$store.state.user.current_app.maincolorTo;
      this.subtitle= this.$store.state.user.current_app.subtitle;
      this.user = this.$store.state.user.user;
      this.rapports = this.$store.state.user.rapports;
      this.currentRapport = this.$store.state.user.current_rapport;
      console.log(this.user);
      console.log(this.rapports);
      console.log(this.type);
      const baseURI = this.server+':3000/'+this.type+'/questions?lang=' + this.$i18n.locale;
      this.$http.get(baseURI)
      .then((result) => {
        this.questions = result.data;
        this.questions.forEach((item)=>{
          this.data[item.id] = {};
          item.question.forEach((item2) => {
            if(this.currentRapport==-1){
              this.data[item.id][item2.id] = null;
              if(item2.type=="range"){
                this.data[item.id][item2.id] = (parseInt(item2.max)+parseInt(item2.min))*0.5;
              }else if(item2.type=="number"){
                if(this.temp[item.id]==undefined) this.temp[item.id] = {};
                this.temp[item.id][item2.id] = null;
              }
            }else{
              this.data[item.id][item2.id] = this.currentRapport.content[item.id][item2.id];
              if(item2.type=="number"){
                if(this.temp[item.id]==undefined) this.temp[item.id] = {};
                this.temp[item.id][item2.id] = this.data[item.id][item2.id]!=null ? this.data[item.id][item2.id].toString().replace(/\s/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, " "):null;
              }
            }
          });
        });
    });
    }
  },
  computed:{
    cssProps() {
      return {
        '--maincolorGradientFrom': this.maincolorFrom,
        '--maincolorGradientTo': this.maincolorTo
      }
    },
  },
  mounted(){
    this.onLngLoad();
    localStorage.setItem("current-view", "Report");
    window.dispatchEvent(new CustomEvent('current-view-localstorage-changed', {
      detail: {
        storage: localStorage.getItem('current-view')
      }
    }));
  }
}
</script>

<style lang="scss">

#app .report{
  ul li a.active {
    background: linear-gradient(122deg, var(--maincolorGradientFrom) 0%, var(--maincolorGradientTo) 100%);
  }
}
.report{
  background: linear-gradient(122deg, rgba(248,248,252,1) 0%, rgba(225,226,232,1) 100%, rgba(0,212,255,1) 100%);
  .range_bubble{
    position: relative;
    margin: 0 auto 0;
    .bubble{
      background: linear-gradient(122deg, var(--maincolorGradientFrom) 0%, var(--maincolorGradientTo) 100%);
      color: white;
      padding: 4px 12px;
      position: absolute;
      border-radius: 4px;
      top:-2rem;
      left: 50%;
      transform: translateX(-50%);

      /*&::after {
        content: "";
        position: absolute;
        width: 2px;
        height: 2px;
        background: red;
        top: -1px;
        left: 50%;
      }*/
    }
  }
  .btn {
    background: linear-gradient(122deg, var(--maincolorGradientFrom) 0%, var(--maincolorGradientTo) 100%);
    box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.3), 4px 4px 15px 0px #fff;
    color:white;
    padding:1em 2em;
    border-radius: 2em;
  }


  .shadow-box {
    box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.3), 4px 4px 15px 0px #fff;
  }

  .inner-shadow-box{
    box-shadow: inset 5px 5px 5px 0px rgba(0,0,0,0.3), inset 4px 4px 15px 0px #fff;
    display: inline-block;
  }
  .report{
    .disabledTab{
      pointer-events: none;
    }
    .row{
      margin-bottom:1em;
    }
  }

  #actoria-logo{
    max-width: 40%;
    margin-top:2em;
  }

  .nav{padding-bottom: 1em;}
}
</style>
