import Vue from 'vue'
import VueI18n from 'vue-i18n'

import translationFR from './locales/fr/translation.json'
import translationEN from './locales/en/translation.json'
import translationDE from './locales/de/translation.json'
import translationIT from './locales/it/translation.json'
import translationES from './locales/es/translation.json'

Vue.use(VueI18n)

const messages = {
  en: translationEN,
  fr: translationFR,
  de: translationDE,
  it: translationIT,
  es: translationES
}

const currentLanguage = navigator.language.split('-')[0] || process.env.VUE_APP_I18N_LOCALE || 'en';

const i18n = new VueI18n({
  locale: localStorage.getItem('lang') || currentLanguage, // set locale
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages, // set locale messages
})

export default i18n