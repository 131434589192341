<template>
    <b-container fluid="xl">
        <b-row align-h="end">
            <b-col md="2" sm="2" style="position:relative;margin-top:1em;">
                <div>
                    <select v-model="$i18n.locale" v-on:change="updateStorage">
                        <option v-for="locale in $i18n.availableLocales" :key="`locale-${locale}`" :value="locale">{{$t(locale)}}</option>
                    </select>
                </div>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
export default {
  data () {
    return {};
  },
  methods: {
    updateStorage() {
        localStorage.setItem('lang',this.$i18n.locale)
    }
  }
}
</script>

<style>
</style>