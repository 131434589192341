export const UserModule = {
  namespaced: true,
  state: {
    user: null,
    rapports: null,
    current_rapport:null,
    current_app:'valo',
  },
  mutations: {
    SET_USER(state, user){
      state.user = user;
    },
    SET_RAPPORT(state, rapports){
      state.rapports = rapports;
    },
    SET_RAPPORT_TYPE(state, rapports,type){
      state.rapports[type] = rapports;
    },
    SET_CURRENT_RAPPORT(state, rapport){
      state.current_rapport = rapport;
    },
    SET_CURRENT_APP(state, type){
      state.current_app = type;
    }
  },
  actions: {
    setUser({commit},user){
      commit('SET_USER',user);
    },
    setRapport({commit},rapports){
      commit('SET_RAPPORT',rapports);
    },
    setRapportType({commit},rapports,type){
      commit('SET_RAPPORT_TYPE',rapports,type);
    },
    setCurrentRapport({commit},rapport){
      commit('SET_CURRENT_RAPPORT',rapport);
    },
    setApp({commit},type){
      commit('SET_CURRENT_APP',type);
    }
  },
  getters: {
    getUser(state){
      return state.user;
    },
    getRapport(state){
      return state.rapports;
    },
    getApp(state){
      return state.current_app;
    }
  }
}
